import React from "react";
import YouTube from "react-youtube";
import { Grid, Card, CardContent, Typography, Box } from "@mui/material";

const videos = [
  {
    id: "video1",
    title: "FRP HANDRAILS(Barricades/ Guard Rails)",
    description: "Description of Video 1",
    url: "https://www.youtube.com/watch?v=fmbMeNVq8To",
  },
  {
    id: "video2",
    title: "FRP HANDRAILS(Barricades/ Guard Rails)",
    description: "Description of Video 1",
    url: "https://www.youtube.com/watch?v=ndAZXK9HTs0",
  },  {
    id: "video3",
    title: "Moulded Walkway",
    description: "Description of Video 1",
    url: "https://www.youtube.com/watch?v=mc1J4Kl8r5M",
  },
];

const extractVideoId = (url) => {
  const match = url.match(/[?&]v=([^&]+)/);
  return match ? match[1] : null;
};

const VideoCard = ({ url, title, description }) => {
  const videoId = extractVideoId(url);
  return (
    videoId && (
      <Card>
        <CardContent>
          <YouTube videoId={videoId} />
          <Typography variant="h5" component="h2">
            {title}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {description}
          </Typography>
        </CardContent>
      </Card>
    )
  );
};

const VideoGrid = () => {
  return (
    <Box margin={"2%"} sx={{ marginTop: "5%" }}>
      <Box data-aos="fade-down">
        <Typography
          align="center"
          variant="h5"
          sx={{
            letterSpacing: "5px",
            paddingLeft: "10px",
            fontWeight: "bold",
          }}
          mb={3}
        >
          Videos
          <Box
            mx="auto"
            mt={"4px"}
            sx={{
              width: "60px",
              borderBottom: "4px solid #ff8229",
              borderRadius: "100px",
            }}
          ></Box>
        </Typography>
      </Box>
      <Grid container spacing={3} justifyContent="center" data-aos="fade">
        {videos &&
          videos?.map((video) => (
            <Grid item xs={12} lg={6} key={video.id}>
              <VideoCard
                url={video?.url}
                title={video?.title}
                description={video.description}
              />
            </Grid>
          ))}
      </Grid>
    </Box>
  );
};

export default VideoGrid;
