import React from "react";
import "./MmsHeroSection.css";
import { Button, Container, Typography } from "@mui/material";
import MmsAbout from "../mmsabout/MmsAbout";
import Footer from "../../../footer/Footer";
import MmsProductCards from "../mmsproductcard/MmsProductCards";

const MmsHeroSection = () => {
  const handleEvent = () => {
    const element = document.getElementById("mmsabout");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <div className="hero" id="home">
        <div className="text" data-aos="fade-down">
          <Container maxWidth="md">
            <Typography
              variant="h2"
              component="h1"
              textTransform={"capitalize"}
              sx={{ fontSize: { xs: "34px", md: "54px" } }}
            >
              VSPL STRUCTURES
            </Typography>
            <Typography>
              (A division of Venkateswara Suppliers Pvt. Ltd.)
            </Typography>
            <Typography
              variant="subtitle1"
              textAlign={"justify"}
              mt={2}
              sx={{ fontSize: { xs: "13px", md: "16px" } }}
            >
              VSPL has over 2 decades of experience in manufacturing L.V
              switchboards for renowned MNC's and other OEM's.
            </Typography>

            <Button
              variant="contained"
              sx={{
                width: "160px",
                padding: "10px 5px",
                mt: 2,
                textTransform: "capitalize",
                background: "#ff8229",
                transition: "all .3s",
                ":hover": {
                  color: "black",
                  background: "#ff8229",
                  fontWeight: "bold",
                },
              }}
              onClick={handleEvent}
            >
              Get Started
            </Button>
          </Container>
        </div>
      </div>
      <MmsAbout />
      <MmsProductCards/>
      <Footer/>
    </>
  );
};

export default MmsHeroSection;
