import skanda from "../../assets/review/skanda-marketing.png";
import harie from "../../assets/review/harie.png";
import maryannInfinta from "../../assets/review/maryann-infinta.png";

export const reviewList = [
  {
    img: skanda,
    name: "Skanda Marketing",
    content:
      "Good product and solutions for FRP Walkways for factory roof cleaning  and solar maintenance pathway.",
  },
  {
    img: harie,
    name: "Harie S",
    content:
      "Good Quality manufacturer...their FRP products are very well finished...Great working with them....",
  },
  {
    img: maryannInfinta,
    name: "Maryann infinta",
    content:
      "The product is good and suitable for all solar rooftop projects..it is safe and strong..installation is easy and perfect.😊🙂",
  },
];
