import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { Button, Stack, useMediaQuery } from "@mui/material";
import logo from "../../assets/vsl-logo.jpg";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { AppBar, DrawerHeader } from "./NavBar.style";
import MenuIcon from "@mui/icons-material/Menu";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";

const drawerWidth = "100%";

const pages = ["Home", "About", "Products", "Structures","Gallery", "Brochure", "Enquiry"];

export default function NavBar() {
  const theme = useTheme();
  const navigate = useNavigate();
  const path = useLocation();
  const mediaQuery = useMediaQuery("(max-width:600px)");
  const [open, setOpen] = React.useState(false);
  const [activeLink, setActiveLink] = React.useState("Home");

  React.useEffect(() => {
    const name = path.pathname;
    if (name === "/") {
      setActiveLink("Home");
    } else if (name === "/#about") {
      console.log("hiii");
      const element = document.getElementById("about");

      if (element) {
        if (window.innerWidth <= theme.breakpoints.values.md) {
          setOpen(false);
        }
        element.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      let result = name.replace(/\//g, "");
      const newLink = capitalizeFirstLetter(result);
      setActiveLink(newLink);
    }

    function capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
  }, [path.pathname]);

  const handleLinkClick = (event, link) => {
    event.preventDefault();
    setActiveLink(link);
    if (
      link === "Products" ||
      link === "Gallery" ||
      link === "Brochure" ||
      link === "Enquiry" ||
      link === 'Structures'
    ) {
      const links = link.toLowerCase();
      navigate(`/${links}`);
      setOpen(false);
    } else if (link === "Home" || link === "About") {
      if (link === "About") {
        navigate("/about");
        setOpen(false);
      } else {
        navigate("/");
        setOpen(false);
      }
    } else {
      const element = document.getElementById(link.toLowerCase());

      if (element) {
        if (window.innerWidth <= theme.breakpoints.values.md) {
          setOpen(false);
        }
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="absolute"
          open={open}
          sx={{
            backgroundColor: "rgba(255,255,255,1)",
            boxShadow: "none",
            borderBottom: "1px solid #ddd",
          }}
        >
          <Toolbar>
            <Stack direction={"row"} alignItems={"center"} gap={4} flex={1}>
              <Box display={"flex"} justifyContent={"space-between"} flex={1}>
                <Box
                  ml={4}
                  sx={{
                    display: { xs: "flex", md: "inline-block" },
                    alignItems: "center",
                  }}
                >
                  <img
                    id="logo"
                    src={logo}
                    alt="logo"
                    width={59}
                    height={51}
                    style={{
                      marginTop: 2,
                      width: 59,
                      height: 51,
                      ...(mediaQuery && {
                        width: 55,
                        height: 48,
                        marginTop: 0,
                      }),
                    }}
                  />
                </Box>
                <IconButton
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  sx={{
                    mr: 2,
                    display: { xs: "flex", sm: "flex", md: "none", lg: "none" },
                  }}
                >
                  <MenuIcon
                    sx={{
                      color: "#ff8229",
                      fontSize: "35px",
                    }}
                  />
                </IconButton>
              </Box>

              <Box
                sx={{
                  display: { xs: "none", md: "flex" },
                  gap: { sm: "3px", md: "6px", lg: "16px" },
                }}
              >
                {pages.map((page) => (
                  <Link
                    to={`#${page}`}
                    key={page}
                    style={{
                      color: "#333",
                      fontSize: "15px",
                      textDecoration: "none",

                      marginLeft: "auto",
                      marginRight:"auto",
                      ...(activeLink === page && {
                        color: "#ff8229",
                        fontWeight: "bold",
                      }),
                    }}
                    onClick={(event) => handleLinkClick(event, page)}
                  >
                    {page}
                  </Link>
                ))}
              </Box>
              <Box
                sx={{
                  display: { xs: "none", sm: "none", md: "flex" },
                  gap: "20px",
                }}
              >
                <Box />
              </Box>
            </Stack>
          </Toolbar>
        </AppBar>

        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
              background: "#ff8229",
            },
          }}
          role="presentation"
          anchor="left"
          open={open}
        >
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "ltr" ? (
                <CloseRoundedIcon sx={{ color: "white", fontSize: "34px" }} />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </DrawerHeader>

          <List>
            {pages.map((text) => (
              <ListItem key={text}>
                <Link
                  to={`#${text}`}
                  onClick={(event) => handleLinkClick(event, text)}
                  style={{
                    color: "#333",
                    fontSize: "14px",
                    textDecoration: "none",

                    marginLeft: "auto",
                      marginRight:"auto",
                    ...(activeLink === text && {
                      color: "white",
                    }),
                  }}
                >
                  <ListItemText primary={text} />
                </Link>
              </ListItem>
            ))}
          </List>
        </Drawer>
      </Box>
    </>
  );
}
