import React, { useState } from "react";
import { Box, Grid, Modal, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import VideoGrid from "./VideoGrid";

import AAIW from "../../assets/products/AAIW.jpeg";
import Barrcade from "../../assets/products/Barrcade.jpeg";
import fencing from "../../assets/products/fencing.jpeg";
import fencing2 from "../../assets/products/fencing2.jpeg";
import fencing3 from "../../assets/products/fencing3.jpeg";
import frp_ladder from "../../assets/products/frp-ladder.jpg";
import FRPFencing from "../../assets/products/FRPFencing.jpeg";
import frpFencing from "../../assets/products/frpFencing.jpg";
import FRPH from "../../assets/products/FRPH.jpeg";
import handler2 from "../../assets/products/handler2.jpeg";
import handler3 from "../../assets/products/handler3.jpeg";
import Handrail from "../../assets/products/Handrail.jpeg";
import ladder2 from "../../assets/products/ladder2.jpeg";
import ladder3 from "../../assets/products/ladder3.jpeg";
import ladder4 from "../../assets/products/ladder4.jpeg";
import ladder5 from "../../assets/products/ladder5.jpeg";
import PGW from "../../assets/products/PGW.jpeg";
import Pgw2 from "../../assets/products/Pgw2.jpeg";
import Pgw3 from "../../assets/products/Pgw3.jpeg";
import Pgw4 from "../../assets/products/Pgw4.jpeg";
import solar_heater from "../../assets/products/solar-heater.png";
import solor_bg1 from "../../assets/products/solor-bg1.jpg";
import walkway from "../../assets/products/walkway.jpeg";
import walkway1 from "../../assets/products/walkway1.jpeg";
import walkway2 from "../../assets/products/walkway2.jpeg";
import walkway3 from "../../assets/products/walkway3.jpeg";
import walkway4 from "../../assets/products/walkway4.jpeg";
import walkway5 from "../../assets/products/walkway5.jpeg";
import walkway6 from "../../assets/products/walkway6.jpeg";
import Footer from "../footer/Footer";

import CarportMMS from "../../assets/mmsproducts/CarportMMS.jpg";
import Elevated from "../../assets/mmsproducts/Elevated.jpg";
import Elevated2 from "../../assets/mmsproducts/Elevated2.jpg";
import WA0020 from "../../assets/mmsproducts/IMG-20240415-WA0020.jpg";
import WA0021 from "../../assets/mmsproducts/IMG-20240415-WA0021.jpg";
import WA0027 from "../../assets/mmsproducts/IMG-20240415-WA0027.jpg"; 
import WA0000 from "../../assets/mmsproducts/IMG-20240416-WA0000.jpg"; 
import WA0002 from "../../assets/mmsproducts/IMG-20240427-WA0002.jpg"; 
import WA0003 from "../../assets/mmsproducts/IMG-20240427-WA0003.jpg"; 
import adjusted_angle from "../../assets/mmsproducts/adjusted-angle.jpeg"; 
import frp_handrails from "../../assets/mmsproducts/frp-handrails.jpg"; 
import frp_handrails2 from "../../assets/mmsproducts/frp-handrails2.jpg"; 
import ground_mounting from "../../assets/mmsproducts/ground-mounting.jpg"; 
import industrial from "../../assets/mmsproducts/industrial.jpg"; 
import industrial2 from "../../assets/mmsproducts/industrial2.jpg"; 
import moduled_walkwayNew from "../../assets/mmsproducts/moduled-walkwayNew.jpg"; 
import rcc_roof_mms from "../../assets/mmsproducts/rcc-roof-mms.jpg"; 
import rcc_roof_mms2 from "../../assets/mmsproducts/rcc-roof-mms2.jpg"; 
import solawater from "../../assets/mmsproducts/solawater-stand.jpg";







const images = [
  // Add your image   URLs here
  AAIW,
  Barrcade,
  fencing,
  fencing2,
  fencing3,
  frp_ladder,
  FRPFencing,
  frpFencing,
  FRPH,
  handler2,
  handler3,
  Handrail,
  ladder2,
  ladder3,
  ladder4,
  ladder5,
  PGW,
  Pgw2,
  Pgw3,
  Pgw4,
  solar_heater,
  solor_bg1,
  walkway,
  walkway1,
  walkway2,
  walkway3,
  walkway4,
  walkway5,
  walkway6,
  CarportMMS,Elevated,Elevated2,WA0020,WA0021,WA0027,WA0000,WA0002,WA0003,adjusted_angle,frp_handrails,frp_handrails2,ground_mounting,industrial,industrial2,moduled_walkwayNew,rcc_roof_mms,rcc_roof_mms2,solawater
];

const Gallery = () => {
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleOpen = (image) => {
    setSelectedImage(image);
    setOpen(true);
    document.body.style.overflow = "hidden"; // Disable scrolling
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedImage(null);
    document.body.style.overflow = "auto"; // Enable scrolling
  };

  return (
    <>
    <Box
      sx={{ marginTop: { xs: 9, md: 10 }, textAlign: "center" }}
      padding="20px"
    >
      <Box data-aos="fade-down">
        <Typography
          align="center"
          variant="h5"
          sx={{
            letterSpacing: "5px",
            paddingLeft: "10px",
            fontWeight: "bold",
          }}
          mb={3}
        >
          Gallery
          <Box
            mx="auto"
            mt={"4px"}
            sx={{
              width: "60px",
              borderBottom: "4px solid #ff8229",
              borderRadius: "100px",
            }}
          ></Box>
        </Typography>
      </Box>
      <Grid container spacing={3} overFlowY="auto">
        {images.map((image, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Box maxWidth={"400px"} maxHeight={"400px"}>
              <img
                src={image}
                alt={`Image ${index}`}
                onClick={() => handleOpen(image)}
                style={{
                  cursor: "pointer",
                  width: "100%",
                  height: "380px",
                  borderRadius: "10px",
                }}
              />
            </Box>
          </Grid>
        ))}
      </Grid>
      <Modal
        open={open}
        onClose={handleClose}
        disableScrollLock={true}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background: "rgba(0, 0, 0, 0.5)",
          overflowY: "auto",
        }}
      >
        <div
          style={{ position: "relative", maxWidth: "100%", maxHeight: "100%" }}
        >
          <IconButton
            style={{ position: "absolute", top: 10, right: 10, color: "#fff" }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
          <img
            src={selectedImage}
            alt="Selected"
            style={{ width: "100%", height: "100%", objectFit: "contain" }}
          />
        </div>
      </Modal>
      <VideoGrid />
    </Box>
    <Footer />
    </>
  );
};

export default Gallery;
