import { Box, Typography } from "@mui/material";
import React from "react";
import frpWalkWay from "../../assets/products/walkway.jpeg";
import BeenhereIcon from '@mui/icons-material/Beenhere';

const About = () => {
  return (
    <div id="about">
      <Box mt={10} overflow={"hidden"} padding={"10px"}>
        <Box data-aos="fade-down">
          <Typography
            align="center"
            variant="h5"
            sx={{
              letterSpacing: "5px",
              paddingLeft: "10px",
              fontWeight: "bold",
            }}
          >
            About
            <Box
              mx="auto"
              mt={"4px"}
              sx={{
                width: "50px",
                borderBottom: "4px solid #ff8229",
                borderRadius: "100px",
              }}
            ></Box>
          </Typography>
        </Box>
        <Box
          display={"flex"}
          justifyContent={{ md: "space-evenly", xs: "center" }}
          width={"100%"}
          mt={5}
          flexDirection={{ xs: "column-reverse", lg: "row" }}
        >
          <Box
            width={{ xs: "100%", md: "50%" }}
            display={"flex"}
            flexDirection={"column"}
            gap={4}
            data-aos="fade-right"
            overflow={"hidden"}
          >
            <Typography textAlign={"justify"}>
              Incorporated In 1997,
              <strong> Venkateswara Suppliers Pvt Ltd </strong>Is One Of A
              Leading Manufacturers Of L.V Switchboard In India. With A
              Successful Track Record Providing High-Quality L.V Switchboards
              For Various Multi National And Fortune 500 Companies In India, Now
              Venkateswara Suppliers Pvt Ltd Has Ventured Into Manufacturing Of
              FRP Products For “Solar Industry”. We Are Committed To Provide
              High-Quality FRP Products At Affordable Costs Nor Compromising On
              Our Core Value Of High Quality And Customer Satisfaction.
            </Typography>
            <Typography textAlign={"justify"}>
              Our Manufacturing Process Involves The Use Of The Best Quality Raw
              Material And State-Of-The-Art Equipment In Accordance With
              International Standards. The Characteristics Of Our FRP Products
              Such As Robust Construction, Corrosion Resistance, Dimensional
              Precision, Low Maintenance, And Durable Finishing Quality Are
              Greatly Appreciated By Our Customers. Moreover, Our Manufacturing
              Unit Is Capable Of Manufacturing Custom Made Moulds In Short
              Duration.
            </Typography>
            <Typography textAlign={"justify"}>
              Our Entire Product Goes Through Several Quality Checks With
              Diverse Parameters To Ensure Flawless Products Is Delivered To Our
              Customer.
            </Typography>
            <Box mt={8} data-aos={'fade'}>
              <Typography
                align="center"
                fontSize={"18px"}
                sx={{
                  letterSpacing: "5px",
                  fontWeight: "bold",
                }}
              >
                Our Mission
              </Typography>
              <Typography mt={1}>
                Our Mission Is To Deliver Innovative, Premium Quality And
                Cost-Effective FRP Products To Customer In India And Abroad.
              </Typography>
            </Box>
            <Box data-aos={'fade'}>
              <Typography
                align="center"
                fontSize={"18px"}
                sx={{
                  letterSpacing: "5px",
                  fontWeight: "bold",
                }}
              >
                Our Vision
              </Typography>
              <Typography mt={1}>
                To Be A Respected Player With Great Expertise In Providing FRP
                Solutions To Solar Industries And Others, Meeting Customer
                Expectations With Respect To Quality, Delivery, And Aftersales
                Services, Thereby Achieving Customer Delight.
              </Typography>
            </Box>
            <Box mt={4} display={{xs:'block',md:'none',lg:'none'}} data-aos={'fade'}>
              <Typography
                align="center"
                fontSize={"18px"}
                sx={{
                  letterSpacing: "5px",
                  paddingLeft: "10px",
                  fontWeight: "bold",
                }}
              >
                Why Choose Us ?
              </Typography>
              <Box mt={1} >
                {[
                  "Customer-Centric Approach",
                  "On-Time Delivery",
                  "Competitive Pricing",
                  "High-Quality Standards",
                  "Advance Infrastructure",
                  'Skilled And Professional Workforce',
                  'Customized Solution'
                ].map((ele)=>(
                    <Box display={'flex'} gap={2} mb={1}>
                     <BeenhereIcon sx={{color:'#ff8229'}}/>
                    <li style={{listStyle:'none'}}>{ele}</li>
                    </Box>
                ))
                }
              </Box>
            </Box>
          </Box>
          <Box
            maxWidth={{ xs: "100%", md: "550px" }}
            width={{ xs: "100%", md: "50%" }}
            data-aos="fade-left"
            overflow={{ xs: "hidden", md: "hidden" }}
            mb={4}
          >
            <img src={frpWalkWay} alt="walkway" width={"100%"} style={{borderRadius:'10px'}}/>
            <Box mt={10} display={{xs:'none',md:'block',lg:'block'}} data-aos={'fade'}>
              <Typography
                align="center"
                fontSize={"18px"}
                sx={{
                  letterSpacing: "5px",
                  paddingLeft: "10px",
                  fontWeight: "bold",
                }}
              >
                Why Choose Us ?
              </Typography>
              <Box mt={1}>
                {[
                  "Customer-Centric Approach",
                  "On-Time Delivery",
                  "Competitive Pricing",
                  "High-Quality Standards",
                  "Advance Infrastructure",
                  'Skilled And Professional Workforce',
                  'Customized Solution'
                ].map((ele)=>(
                    <Box display={'flex'} gap={2} mb={1}>
                     <BeenhereIcon sx={{color:'#ff8229'}}/>
                    <li style={{listStyle:'none'}}>{ele}</li>
                    </Box>
                ))
                }
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default About;
