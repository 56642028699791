import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Box, CardActionArea } from "@mui/material";
import { productList } from "./mmsproductList";
import { Link } from "react-router-dom";

const MmsProductCards = () => {
  const [hoveredImage, setHoveredImage] = React.useState(null);

  return (
    <Box mt={15} mb={4}>
      <Box data-aos="fade-down">
        <Typography
          align="center"
          variant="h5"
          sx={{
            letterSpacing: "5px",
            fontWeight: "bold",
          }}
        >
          MMS Products
          <Box
            mx="auto"
            mt={"4px"}
            sx={{
              width: "140px",
              borderBottom: "4px solid #ff8229",
              borderRadius: "100px",
            }}
          ></Box>
        </Typography>
      </Box>

      <Box
        padding={"16px"}
        display={"flex"}
        flexWrap={"wrap"}
        gap={"16px"}
        justifyContent={"center"}
      >
        {productList.map((product, index) => (
          <Box key={index}>
            <Link
              to="/MMS_products"
              state={{ Index: index }}
              style={{ textDecoration: "none" }}
            >
              <Card
                sx={{
                  maxWidth: 345,
                  width: "500px",
                  mb: 2,
                }}
                data-aos="zoom-in"
                onMouseEnter={() => setHoveredImage(index)}
                onMouseLeave={() => setHoveredImage(null)}
              >
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height="200"
                    image={product.img}
                    alt={product.name}
                    sx={{
                      backgroundSize: "cover ",
                      transition: "transform 0.3s ease",
                      transform:
                        hoveredImage === index ? "scale(1.1)" : "scale(0.9)",
                    }}
                  />
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="body1"
                      fontWeight={"bold"}
                      component="div"
                      align="center"
                      sx={{
                        ":hover": {
                          color: "#ff8229",
                        },
                      }}
                      fontSize={{xs:'13px',md:'15px'}}
                    >
                      {product.name}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Link>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default MmsProductCards;
