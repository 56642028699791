import React from "react";
import "./HeroSection.css";
import { Box, Button, Container, Typography } from "@mui/material";
import About from "../about/About";
import ProductCards from "../ProductCards";
import ReviewCard from "../review/ReviewCard";
import Footer from "../footer/Footer";
import { useNavigate } from "react-router-dom";

const HeroSection = () => {
  const navigate = useNavigate()
  const handleEvent = (value) => {
    if(value === 'about'){
      const element = document.getElementById("about");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
    else if(value === 'frp'){
      navigate('/products')
    }
    else if(value === 'mms'){
      navigate('/structures')
    }
    
  };
  return (
    <>
      <div className="hero" id="home">
        <div className="text" data-aos="fade-down">
          <Container maxWidth="md">
            <Typography
              variant="h2"
              component="h1"
              textTransform={"capitalize"}
              sx={{ fontSize: { xs: "44px", md: "64px" } }}
            >
              Venkateswara Suppliers Private Limited
            </Typography>
            <Typography
              variant="subtitle1"
              textAlign={"justify"}
              mt={2}
              sx={{ fontSize: { xs: "13px", md: "16px" } }}
            >
              We are committed to provide high-quality FRP products at
              affordable costs nor compromising on our core value of high
              quality and customer satisfaction.
            </Typography>
            <Box
              display={"flex"}
              flexDirection={{ xs: "column", md: "row" }}
              gap={"8px"}
              data-aos='fade'
            >
              <Button
                variant="contained"
                sx={{
                  width: "160px",
                  padding: "10px 5px",
                  mt: 2,
                  textTransform: "capitalize",
                  background: "#ff8229",
                  transition: "all .3s",
                  ":hover": {
                    color: "black",
                    background: "#ff8229",
                    fontWeight: "bold",
                  },
                }}
                onClick={()=>handleEvent('about')}
              >
                Get Started
              </Button>
              <Button
                variant="contained"
                sx={{
                  width: "160px",
                  padding: "10px 5px",
                  mt: 2,
                  textTransform: "capitalize",
                  background: "#ff8229",
                  transition: "all .3s",
                  ":hover": {
                    color: "black",
                    background: "#ff8229",
                    fontWeight: "bold",
                  },
                }}
                onClick={() => handleEvent("frp")}
              >
                FRP
              </Button>
              <Button
                variant="contained"
                sx={{
                  width: "160px",
                  padding: "10px 5px",
                  mt: 2,
                  textTransform: "capitalize",
                  background: "#ff8229",
                  transition: "all .3s",
                  ":hover": {
                    color: "black",
                    background: "#ff8229",
                    fontWeight: "bold",
                  },
                }}
                onClick={() => handleEvent("mms")}
              >
                MMS
              </Button>
            </Box>
          </Container>
        </div>
      </div>
      <About />
      <ProductCards />
      <ReviewCard />
      <Footer />
    </>
  );
};

export default HeroSection;
