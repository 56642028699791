import React, { useState } from "react";
import { Box, TextField, Button, Grid, Typography, Stack } from "@mui/material";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";

const Enquiry = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Add logic to handle form submission here
    console.log(formData);
    // Reset form fields
    setFormData({
      name: "",
      email: "",
      message: "",
    });
  };

  return (
    <>
      <Box sx={{ marginTop: { xs: 9, md: 11 }, margin: "2%" }}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} sm={8} md={6} lg={8}>
            <form onSubmit={handleSubmit}>
              <Box data-aos="fade-down">
                <Typography
                  align="center"
                  variant="h5"
                  sx={{
                    letterSpacing: "5px",
                    paddingLeft: "10px",
                    fontWeight: "bold",
                  }}
                  mb={3}
                >
                  Enquiry
                  <Box
                    mx="auto"
                    mt={"4px"}
                    sx={{
                      width: "60px",
                      borderBottom: "4px solid #ff8229",
                      borderRadius: "100px",
                    }}
                  ></Box>
                </Typography>
              </Box>
              <Box display={"flex"} justifyContent={"space-between"}>
                <Box>
                  <Stack>
                    <Box
                      display="flex"
                      gap={"8px"}
                      flexDirection={{ xs: "column", md: "row" }}
                    >
                      <Box display="flex" gap="8px">
                        <Typography
                          component={"a"}
                          sx={{
                            color: "black",
                            ":hover": {
                              color: "#ff8229",
                            },
                          }}
                          href="tel:+91 9585591500"
                        >
                          <LocalPhoneIcon />
                        </Typography>
                        <Typography
                          variant="body1"
                          fontWeight={"bold"}
                          fontSize={{ xs: "14px", md: "16px" }}
                        >
                          Phone
                        </Typography>
                      </Box>

                      <Typography
                        component={"a"}
                        href="tel:+91 9585591500"
                        sx={{
                          textDecoration: "none",
                          color: "#848785",
                          ":hover": {
                            color: "#ff8229",
                          },
                        }}
                        fontSize={{ xs: "14px", md: "16px" }}
                      >
                        +91 000000000
                      </Typography>
                    </Box>
                  </Stack>
                </Box>
                <Box padding={"5px"}>
                  <Stack>
                    <Box
                      display="flex"
                      flexDirection={{ xs: "column", md: "row" }}
                      gap={"8px"}
                    >
                      <Box display="flex" gap="8px">
                        <Typography
                          component={"a"}
                          href="mailto: info@vslindia.com"
                          sx={{
                            color: "black",
                            ":hover": {
                              color: "#ff8229",
                            },
                          }}
                        >
                          <EmailIcon />
                        </Typography>
                        <Typography
                          variant="body1"
                          fontWeight={"bold"}
                          fontSize={{ xs: "14px", md: "16px" }}
                        >
                          Email
                        </Typography>
                      </Box>

                      <Typography
                        component={"a"}
                        href="mailto: info@vslindia.com"
                        sx={{
                          textDecoration: "none",
                          color: "#848785",
                          ":hover": {
                            color: "#ff8229",
                          },
                        }}
                        fontSize={{ xs: "14px", md: "16px" }}
                      >
                        email
                      </Typography>
                      <Typography
                        component={"a"}
                        href="mailto: "
                        sx={{
                          textDecoration: "none",
                          color: "#848785",
                          ":hover": {
                            color: "#ff8229",
                          },
                        }}
                        fontSize={{ xs: "14px", md: "16px" }}
                      >
                        @Gamil.com
                      </Typography>
                    </Box>
                  </Stack>
                </Box>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Email"
                    name="email"
                    type="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    label="Message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#ff8229",
                      ":hover": {
                        backgroundColor: "#333",
                        color: "#ff8229",
                      },
                    }}
                    type="submit"
                    fullWidth
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
          <Grid item xs={12} sm={8} md={6} lg={8}>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d31336.518063365358!2d76.97803!3d10.958481!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba85b05385708dd%3A0xe590d633d9e1e270!2sVenkateswara%20Suppliers%20Private%20Limited!5e0!3m2!1sen!2sin!4v1713837895349!5m2!1sen!2sin"
              width="100%"
              height="400"
              frameBorder="0"
              allowFullScreen
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </Grid>
        </Grid>
      </Box>

    </>
  );
};

export default Enquiry;
