import React from "react";
import Carousel from "react-material-ui-carousel";
import { Paper, Typography, Box } from "@mui/material";
import { reviewList } from "./review";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";

export default function ReviewCard(props) {

  return (
    <Box data-aos="fade-down" mt={14}>
      <Typography
        align="center"
        variant="h5"
        sx={{
          letterSpacing: "5px",
          paddingLeft: "10px",
          fontWeight: "bold",
        }}
      >
        Review
        <Box
          mx="auto"
          mt={"4px"}
          sx={{
            width: "60px",
            borderBottom: "4px solid #ff8229",
            borderRadius: "100px",
          }}
        ></Box>
      </Typography>
      <Carousel
        sx={{
          mb: 10,
          padding: "5px",
          boxShadow: "none",
        }}
        indicatorIconButtonProps={{
          style: {
            padding: "2px",
            color: "#ddd",
          },
        }}
        activeIndicatorIconButtonProps={{
          style: {
            backgroundColor: "#ff8229",
          },
        }}
        indicatorContainerProps={{
          style: {
            marginTop: "30px",
            textAlign: "center",
            zIndex: 999,
          },
        }}
      >
        {reviewList.map((item, i) => (
          <Item key={i} item={item} />
        ))}
      </Carousel>
    </Box>
  );
}

function Item(props) {
  return (
    <Box>
      <Paper
        sx={{
          width: "100%",
          height: { xs: "300px", md: "250px" },
          mt: 3,
          background: "#ff8229",
          display: { xs: "block", md: "flex" },
        }}
      >
        <Box
          display={"flex"}
          flexDirection={{ xs: "column", md: "row", lg: "row" }}
          width={"100%"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Box padding={"10px"} width={"20%"} mx={{ xs: "auto", md: "1px" }}>
            <img src={props.item.img} alt={props.item.name} />
            <Typography
              mt={2}
              variant="h5"
              fontSize={{ xs: "14px", md: "20px" }}
              fontWeight={"bold"}
              color={"white"}
            >
              {props.item.name}
            </Typography>
          </Box>
          <Box mx={{ xs: "auto", md: "1px" }} width={{ xs: "100%", md: "50%" }}>
            <FormatQuoteIcon
              sx={{
                transform: "rotate(180deg)",
                fontSize: "44px",
                color: "#424242",
              }}
            />
            <Typography
              fontWeight={100}
              fontStyle={"italic"}
              fontSize={{ xs: "14px", md: "22px" }}
              paddingLeft={"20px"}
              color={"white"}
            >
              {props.item.content}
            </Typography>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
}
