import * as React from "react";
import { useEffect } from "react";

import { Box, styled, Typography } from "@mui/material";
import {
  FRP_PULTRUDED_GRATING_WALKWAY,
  FRP_HANDRAILS,
  Angle_Adjusted,
  Moulded_Walkway,
  FRP_Fencing,
  FRP_LADDER_AND_STAIRCASE,
  Ground_Mounting_MMS,
  Carport_MMS,
  RCC_Flat_Roof_MMS,
  INDUSTRIAL,
  Elevated,
  Solar_Water_Heater,
} from "./productList";
import Aos from "aos";
import Footer from "../../../footer/Footer";

const StyledBox = styled(Box)`
  width: 100%;
  height: 100%;
`;

function MMSProduct() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <Box width={'100%'}>
      <Box mt={{ xs: 8, lg: 10 }}>
        <Typography
          align="center"
          variant="h5"
          sx={{
            letterSpacing: "5px",
            paddingLeft: "10px",
            fontWeight: "bold",
          }}
        >
          MMS Products
          <Box
            mx="auto"
            mt={"4px"}
            sx={{
              width: "60px",
              borderBottom: "4px solid #ff8229",
              borderRadius: "100px",
            }}
          ></Box>
        </Typography>
      </Box>
      <Box
        padding={"16px"}
        display={"flex"}
        flexWrap={"wrap"}
        gap={"60px"}
        justifyContent={"center"}
        data-aos="fade"
        data-aos-easing="linear"
        data-aos-duration="2000"
        overFlow="hidden"
        flex={1}
        
      >
        <StyledBox>
          <Ground_Mounting_MMS />
        </StyledBox>
        <StyledBox>
          <Solar_Water_Heater />
        </StyledBox>
        <StyledBox>
          <Elevated />
        </StyledBox>
        <StyledBox>
          <Carport_MMS />
        </StyledBox>
        <StyledBox>
          <RCC_Flat_Roof_MMS />
        </StyledBox>
        <StyledBox>
          <INDUSTRIAL />
        </StyledBox>
      </Box>

      <Footer />
    </Box>
  );
}

export default MMSProduct;
