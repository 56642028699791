import React, { useState } from 'react';
import {Box, Grid, Modal, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import VideoGrid from './VideoGrid';
import Brochure1 from '../../assets/Brochure/1.png'
import Brochure2 from '../../assets/Brochure/2.png'
import Footer from '../footer/Footer';

const images = [
  // Add your image URLs here
  Brochure1,Brochure2
];

const Brochure = () => {
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleOpen = (image) => {
    setSelectedImage(image);
    setOpen(true);
    document.body.style.overflow = 'hidden'; // Disable scrolling
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedImage(null);
    document.body.style.overflow = 'auto'; // Enable scrolling
  };

  return (
    <>
    <Box sx={{margin:{lg:'4%',xs:'14%'},textAlign:'center'}}>
      <Box data-aos="fade-down">
        <Typography
          align="center"
          variant="h5"
          sx={{
            letterSpacing: "5px",
            paddingLeft: "10px",
            fontWeight: "bold",
          }}
          mt={{xs:8,md:10}}
          mb={3}
        >
          Brochure
          <Box
            mx="auto"
            mt={"4px"}
            sx={{
              width: "60px",
              borderBottom: "4px solid #ff8229",
              borderRadius: "100px",
            }}
          ></Box>
        </Typography>
      </Box>
      <Grid container spacing={3} justifyContent={'center'}>
        {images.map((image, index) => (
          <Grid item xs={12} sm={6} md={4} lg={6} key={index}>
            <img
              src={image}
              alt={`Image ${index}`}
              onClick={() => handleOpen(image)}
              style={{ cursor: 'pointer', width: '100%', height: '100%' }}
            />
          </Grid>
        ))}
      </Grid>
      <Modal
        open={open}
        onClose={handleClose}
        disableScrollLock={true}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          background: 'rgba(0, 0, 0, 0.5)', // Lightly opaque black background
        }}
      >
        <div style={{ position: 'relative', maxWidth: '100%', maxHeight: '100%' }}>
          <IconButton
            style={{ position: 'absolute', top: 10, right: 10, color: '#fff' }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
          <img
            src={selectedImage}
            alt="Selected"
            style={{ width: '100%', height: '100%', objectFit: 'contain' }}
          />
        </div>
      </Modal>
   
    </Box>
    <Footer/>
    </>
  );
};

export default Brochure;
