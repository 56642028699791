import rccRoof from "../../../../assets/mmsproducts/rcc-roof-mms.jpg";
import industrial from "../../../../assets/mmsproducts/industrial.jpg";
import Elevated from "../../../../assets/mmsproducts/Elevated.jpg";
import groundMounting from "../../../../assets/mmsproducts/ground-mounting.jpg";
import solarWater from "../../../../assets/mmsproducts/solawater-stand.jpg";
import CarportMMS from "../../../../assets/mmsproducts/CarportMMS.jpg";

export const productList = [
  {
    name: "RCC Flat Roof MMS",
    img: rccRoof,
  },
  {
    name: "Industrial",
    img: industrial,
  },
  {
    name: "Elevated",
    img: Elevated,
  },
  {
    name: "Ground Mounting MMS",
    img: groundMounting,
  },
  {
    name: "Solar Water Heater Tank with FRP stand",
    img: solarWater,
  },
  {
    name: "Carport MMS",
    img: CarportMMS,
  },
];
