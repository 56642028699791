import React from "react";
import HeroSection from "./components/hero/HeroSection";
import Aos from "aos";
import "aos/dist/aos.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import NavBar from "./components/navbar/NavBar";
import Product from "./components/Product/Product";
import Gallery from "./components/Gallery/Gallery";
import Brochure from "./components/Gallery/Brochure";
import Enquiry from "./components/enquiry/Enquiry";
import About from "./components/about/About";
import Footer from "./components/footer/Footer";
import MmsHeroSection from "./components/mmspage/components/mmshero/MmsHeroSection";
import MMSProduct from "./components/mmspage/components/mmsproduct/Product";
function App() {
  React.useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <>
      <BrowserRouter>
        <NavBar />
        <Routes>
          <Route path="/" element={<HeroSection />} />
          <Route
            path="/about"
            element={
              <>
                <About />
                <Footer />
              </>
            }
          />
          <Route path="/enquiry" element={<Enquiry />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/brochure" element={<Brochure />} />
          <Route path="/MMS_products" element={<MMSProduct/>} />
          <Route path="/products" element={<Product/>} />
          <Route path="/structures" element={<MmsHeroSection />} />
          
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
