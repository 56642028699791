import walkway from "../../../../assets/products/walkway6.jpeg";
import Handrail from "../../../../assets/mmsproducts/frp-handrails2.jpg";
import AAIW from "../../../../assets/mmsproducts/adjusted-angle.jpeg";
import Mouldedsheet from "../../../../assets/mmsproducts/moduled-walkwayNew.jpg";
import FRPFencing from "../../../../assets/products/frpFencing.jpg";
import ladder2 from "../../../../assets/products/ladder2.jpeg";

import ground_mounting from "../../../../assets/mmsproducts/ground-mounting.jpg";
import ElevatedImg from "../../../../assets/mmsproducts/Elevated.jpg";
import CarportMMS from "../../../../assets/mmsproducts/CarportMMS.jpg";
import rcc from "../../../../assets/mmsproducts/rcc-roof-mms.jpg";
import solawater from "../../../../assets/mmsproducts/solawater-stand.jpg";
import industrial from "../../../../assets/mmsproducts/industrial.jpg";

import Typography from "@mui/material/Typography";

import { Box, List, ListItem, ListItemIcon } from "@mui/material";
import { Circle, DoneOutline, LineWeight } from "@mui/icons-material";

export const productList = [
  {
    ID: 0,
    name: "FRP PULTRUDED GRATING WALKWAY",
    img: walkway,
    Descripe:
      "A State-Of-The-Art Manufacturing Facility Produces Pultruded Profiles (Pultrusion Profiles- ‘I’ Section For Producing Horizontal And Vertical Walkway For Solar Rooftops). We Have All The Necessary Testing Certificates To Prove That Our Walkway Supplied Does Have A Longer Life Than The Other Moulded Grating Walkways.",
    Features: [
      "Light weight – Very Strong",
      "Easy to transport",
      "Anti-Skid Design",
      "UV Resistant",
      "Long life",
      "Corrosion Resistant",
      "Our Walkways have less than one third the weight of steel",
      "Easy to Install at site",
    ],
    Size: [
      "250mm X 20mm X 2000mm",
      "270mm X 20mm X 2000mm",
      "310mm X 20mm X 2000mm",
      "405mm X 20mm X 2000mm",
    ],
  },
  {
    ID: 1,
    name: "FRP HANDRAILS(Barricades/ Guard Rails)",
    img: Handrail,
    Descripe:
      "They Are Installed At Site As An Added Measure To Prevent From Falling Through The Roof Space.   They Also Help In Increasing The Safety And Accessibility To Many Rooftops. It Prevents Many Dangerous And Injurious Falls From The Rooftops.",
    Features: [
      "Light weight and easy to install",
      "Anti-Corrosive",
      "Cost effective",
      "Durable that steel handrails",
      "Strong enough to take any kind of load",
      "UV Resistant",
      "Comparatively much sturdier",
      "Power to withstand weather conditions",
    ],
    Size: ["900mm X 50mm X 50mm", "750mm X 50mm X 50mm"],
  },
  {
    ID: 2,
    name: "Angle Adjusted | Inclination Walkway",
    img: AAIW,
    Descripe: "",
    Features: [
      "Can be adjust to maintain angle flat walkway",
      "Light Weight",
      "Easy to Transport & Install at site",
      "No Corrosion",
      "Anti-Skid Deign",
      "UV Resistance",
    ],
    Size: [
      "250mm X 20mm X 2000mm",
      "270mm X 20mm X 2000mm",
      "310mm X 20mm X 2000mm",
      "405mm X 20mm X 2000mm",
    ],
  },
  {
    ID: 3,
    name: "Moulded Walkway",
    img: Mouldedsheet,
    Descripe: "",
    Features: [
      "Box Type and Sheet Type",
      "Can be adjust to maintain angle flat walkway",
      "Light Weight",
      "Easy to Transport & Install at site",
      "No Corrosion",
      "Anti-Skid Deign",
      "UV Resistance",
    ],
    Size: [],
  },
  {
    ID: 4,
    name: "FRP Fencing",
    img: FRPFencing,
    Descripe: "",
    Features: [],
    Size: [],
  },
  {
    ID: 5,
    name: "FRP LADDER AND STAIRCASE",
    img: ladder2,
    Descripe: "",
    Features: [],
    Size: [],
  },
];

export const Ground_Mounting_MMS = () => {
  return (
    <Box sx={{ width: "70%", height: "auto", display: "block", mx: "auto" }}>
      <Box
        sx={{
          maxHeight: { lg: "600px", xs: "300px" },
          width: "100%",
          height: "100%",
          overflow: "hidden",
          borderRadius: "10px",
          justifySelf: "center",
        }}
        data-aos="fade"
      >
        <img width={"100%"} src={ground_mounting} alt="" />
      </Box>
      <Box mt={2}>
        <Typography
          variant="h4"
          fontWeight={"bold"}
          paddingBottom={2}
          align="left"
          fontSize={{ xs: "20px", md: "24px" }}
          data-aos="fade"
        >
          Ground Mounting MMS
        </Typography>
      </Box>
    </Box>
  );
};

export const Carport_MMS = () => {
  return (
    <Box sx={{ width: "70%", height: "auto", display: "block", mx: "auto" }}>
      <Box
        sx={{
          maxHeight: { lg: "600px", xs: "300px" },
          width: "100%",
          height: "100%",
          overflow: "hidden",
          borderRadius: "10px",
          justifySelf: "center",
        }}
        data-aos="fade"
      >
        <img width={"100%"} src={CarportMMS} alt="" />
      </Box>
      <Box mt={2}>
        <Typography
          variant="h4"
          fontWeight={"bold"}
          paddingBottom={2}
          align="left"
          fontSize={{ xs: "20px", md: "24px" }}
          data-aos="fade"
        >
          Carport MMS
        </Typography>
      </Box>
    </Box>
  );
};

export const RCC_Flat_Roof_MMS = () => {
  return (
    <Box sx={{ width: "70%", height: "auto", display: "block", mx: "auto" }}>
      <Box
        sx={{
          maxHeight: { lg: "600px", xs: "300px" },
          width: "100%",
          height: "100%",
          overflow: "hidden",
          borderRadius: "10px",
          justifySelf: "center",
        }}
        data-aos="fade"
      >
        <img width={"100%"} src={rcc} alt="" />
      </Box>
      <Box mt={2}>
        <Typography
          variant="h4"
          fontWeight={"bold"}
          paddingBottom={2}
          align="left"
          fontSize={{ xs: "20px", md: "24px" }}
          data-aos="fade"
        >
          RCC Flat Roof MMS
        </Typography>
        <Typography
          variant="h5"
          fontWeight={"bold"}
          align="left"
          fontSize={{ xs: "20px", md: "24px" }}
          data-aos="fade"
        >
          {" "}
          SIZES
        </Typography>
        <List>
          {["1x4", "2x2", "2x3", "2x4", "2x5"].map((ListValue, index) => (
            <ListItem key={index} data-aos="fade">
              <ListItemIcon>
                <DoneOutline />
              </ListItemIcon>
              <Typography
                variant="p"
                letterSpacing={2}
                fontWeight={"medium"}
                align="left"
                fontSize={{ xs: "12px", md: "18px" }}
              >
                {ListValue}
              </Typography>
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  );
};

export const INDUSTRIAL = () => {
  return (
    <Box sx={{ width: "70%", height: "auto", display: "block", mx: "auto" }}>
      <Box
        sx={{
          maxHeight: { lg: "600px", xs: "300px" },
          width: "100%",
          height: "100%",
          overflow: "hidden",
          borderRadius: "10px",
          justifySelf: "center",
        }}
        data-aos="fade"
      >
        <img width={"100%"} src={industrial} alt="" />
      </Box>
      <Box mt={2}>
        <Typography
          variant="h4"
          fontWeight={"bold"}
          paddingBottom={2}
          align="left"
          fontSize={{ xs: "20px", md: "24px" }}
          data-aos="fade"
        >
          INDUSTRIAL
        </Typography>
      </Box>
    </Box>
  );
};

export const Elevated = () => {
  return (
    <Box sx={{ width: "70%", height: "auto", display: "block", mx: "auto" }}>
      <Box
        sx={{
          maxHeight: { lg: "600px", xs: "300px" },
          width: "100%",
          height: "100%",
          overflow: "hidden",
          borderRadius: "10px",
          justifySelf: "center",
        }}
        data-aos="fade"
      >
        <img width={"100%"} src={ElevatedImg} alt="" />
      </Box>
      <Box mt={2}>
        <Typography
          variant="h4"
          fontWeight={"bold"}
          paddingBottom={2}
          align="left"
          fontSize={{ xs: "20px", md: "24px" }}
          data-aos="fade"
        >
          Elevated
        </Typography>
        <List>
          {[
            "Elevated RM is 3mm rectangle pipe HDG",
            "Standard size has Galvanized 2mm thickness 'C' section",
          ].map((ListValue, index) => (
            <ListItem key={index} data-aos="fade">
              <ListItemIcon>
                <DoneOutline />
              </ListItemIcon>
              <Typography
                variant="p"
                letterSpacing={2}
                fontWeight={"medium"}
                align="left"
                fontSize={{ xs: "12px", md: "18px" }}
              >
                {ListValue}
              </Typography>
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  );
};

export const Solar_Water_Heater = () => {
  return (
    <Box sx={{ width: "70%", height: "auto", display: "block", mx: "auto" }}>
      <Box
        sx={{
          maxHeight: { lg: "600px", xs: "300px" },
          width: "100%",
          height: "100%",
          overflow: "hidden",
          borderRadius: "10px",
          justifySelf: "center",
        }}
        data-aos="fade"
      >
        <img width={"100%"} src={solawater} alt="" />
      </Box>
      <Box mt={2}>
        <Typography
          variant="h4"
          fontWeight={"bold"}
          paddingBottom={2}
          align="left"
          fontSize={{ xs: "20px", md: "24px" }}
          data-aos="fade"
        >
          Solar Water Heater Tank with FRP stand
        </Typography>
      </Box>
    </Box>
  );
};
