import React from "react";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import XIcon from "@mui/icons-material/X";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
const Footer = () => {
  const links = ["about", "products","structures", "gallery", "brochure", "enquiry"];
  return (
    <div>
      <Box sx={{ width: "100%", height: "220px", background: "#333" }}>
        <Box
          display="flex"
          justifyContent={"center"}
          color={"white"}
          gap={"16px"}
        >
          <a
            href="https://www.facebook.com/VenkateswaraSuppliersPrivateLimited"
            target="_blank"
            style={{ color: "white" }}
          >
            <FacebookOutlinedIcon
              sx={{
                mt: 6,
                transition: "all .3s",
                ":hover": { color: "#ff8229", fontSize: "34px" },
              }}
            />
          </a>
          <a
            href="https://twitter.com/vsplindia"
            target="_blank"
            style={{ color: "white" }}
          >
            <XIcon
              sx={{
                mt: 6,
                transition: "all .3s",
                ":hover": { color: "#ff8229", fontSize: "34px" },
              }}
            />
          </a>
          <a
            href="https://www.instagram.com/vsplindia/"
            target="_blank"
            style={{ color: "white" }}
          >
            <InstagramIcon
              sx={{
                mt: 6,
                transition: "all .3s",
                ":hover": { color: "#ff8229", fontSize: "34px" },
              }}
            />
          </a>
          <a
            href="https://www.linkedin.com/company/venkateswara-suppliers-pvt-ltd/"
            target="_blank"
            style={{ color: "white" }}
          >
            <LinkedInIcon
              sx={{
                mt: 6,
                transition: "all .3s",
                ":hover": { color: "#ff8229", fontSize: "34px" },
              }}
            />
          </a>
        </Box>
        <Box
          display="flex"
          justifyContent={"center"}
          gap={"8px"}
          mt={2}
          flexWrap={"wrap"}
        >
          <Link to={"/"} style={{ color: "white", textDecoration: "none" }}>
            <Typography fontSize={{ xs: "12px", md: "16px" }}>Home</Typography>
          </Link>
          {links.map((link) => (
            <Link
              to={`/${link}`}
              style={{
                textTransform: "capitalize",
                color: "white",
                textDecoration: "none",
              }}
            >
              <Typography fontSize={{ xs: "12px", md: "16px" }}>
                {link}
              </Typography>
            </Link>
          ))}
        </Box>
        <Box>
          <Typography
            align="center"
            mt={{ xs: 2, md: 3 }}
            color={"#ddd"}
            fontSize={{ xs: "12px", md: "16px" }}
          >
            © Copyright 2024 Venkateswara Suppliers Private Limited
          </Typography>
        </Box>
      </Box>
    </div>
  );
};

export default Footer;
