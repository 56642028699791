import { Box, Typography } from "@mui/material";
import React from "react";
import frpWalkWay from "../../../../assets/mmsproducts/IMG-20240416-WA0000.jpg";
import VerifiedIcon from '@mui/icons-material/Verified';

const MmsAbout = () => {
  return (
    <div id="mmsabout">
      <Box mt={10} overflow={"hidden"} padding={"10px"}>
        <Box data-aos="fade-down">
          <Typography
            align="center"
            variant="h5"
            sx={{
              letterSpacing: "5px",
              paddingLeft: "10px",
              fontWeight: "bold",
            }}
          >
            About
            <Box
              mx="auto"
              mt={"4px"}
              sx={{
                width: "50px",
                borderBottom: "4px solid #ff8229",
                borderRadius: "100px",
              }}
            ></Box>
          </Typography>
        </Box>
        <Box
          display={"flex"}
          justifyContent={{ md: "space-evenly", xs: "center" }}
          width={"100%"}
          mt={5}
          flexDirection={{ xs: "column-reverse", lg: "row" }}
        >
          <Box
            width={{ xs: "100%", md: "50%" }}
            display={"flex"}
            flexDirection={"column"}
            gap={4}
            data-aos="fade-right"
            overflow={"hidden"}
          >
            <Typography textAlign={"justify"}>
              VSPL has over 2 decades of experience in manufacturing L.V
              switchboards for renowned MNC's and other OEM's
            </Typography>
            <Typography textAlign={"justify"}>
              Using this extensive experience of over 2 decades our team of
              design engineers have come out with a new factory for Module
              Mounting Structures. We manufacture a complete range of
              Residential, Commercial and Industrial scale of Module Mounting
              Structures.
            </Typography>
            <Typography textAlign={"justify"}>
              Our in house team has subjected these designs and the structures
              have passed wind load tests. The STAAD reports developed for our
              structures are our testimony to this claim.
            </Typography>
           
            <Box
              mt={2}
              data-aos={"fade"}
            >
              <Typography
                fontSize={"18px"}
                sx={{
                  letterSpacing: "5px",
                  paddingLeft: "10px",
                  fontWeight: "bold",
                }}
              >
                Our facility
              </Typography>
              <Box mt={1}>
                {[
                  "Role Forming Machine",
                  "Presses Shearing and Cutting Macine",
                  "Power Presses for Punching",
                  "Solar Water Heater manufacturing facility",
                  "Advance Welding and Drilling machinery."
                ].map((ele) => (
                  <Box display={"flex"} gap={2} mb={1}>
                    <VerifiedIcon sx={{ color: "#ff8229" }} />
                    <li style={{ listStyle: "none" }}>{ele}</li>
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
          <Box
            maxWidth={{ xs: "100%", md: "550px" }}
            width={{ xs: "100%", md: "50%" }}
            data-aos="fade-left"
            overflow={{ xs: "hidden", md: "hidden" }}
            mb={4}
          >
            <img
              src={frpWalkWay}
              alt="walkway"
              width={"100%"}
              style={{ borderRadius: "10px" }}
            />
           
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default MmsAbout;
