import * as React from "react";
import { useEffect } from "react";

import { Box, Typography } from "@mui/material";
import {
  FRP_PULTRUDED_GRATING_WALKWAY,
  FRP_HANDRAILS,
  Angle_Adjusted,
  Moulded_Walkway,
  FRP_Fencing,
  FRP_LADDER_AND_STAIRCASE,
} from "./productList";
import Aos from "aos";
import Footer from "../footer/Footer";

function Product() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <Box mt={{ xs: 8, lg: 10 }} sx={{ textAlign: "center" }}>
      <Box >
          <Typography
            align="center"
            variant="h5"
            sx={{
              letterSpacing: "5px",
              paddingLeft: "10px",
              fontWeight: "bold",
            }}
          >
            Products
            <Box
              mx="auto"
              mt={"4px"}
              sx={{
                width: "60px",
                borderBottom: "4px solid #ff8229",
                borderRadius: "100px",
              }}
            ></Box>
          </Typography>
        </Box>
        <Box
          padding={"16px"}
          display={"flex"}
          flexWrap={"wrap"}
          gap={"100px"}
          justifyContent={"center"}
          data-aos="fade"
          data-aos-easing="linear"
          data-aos-duration="2000"
          overFlow="hidden"
        >
          <FRP_PULTRUDED_GRATING_WALKWAY />
          <FRP_HANDRAILS />
          <Angle_Adjusted />
          <Moulded_Walkway />
          <FRP_Fencing />
          <FRP_LADDER_AND_STAIRCASE />
        </Box>
      </Box>
      <Footer/>
    </>
  );
}

export default Product;
