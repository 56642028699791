import walkway from "../../assets/products/walkway6.jpeg";
import Handrail from "../../assets/mmsproducts/frp-handrails.jpg";
import AAIW from "../../assets/mmsproducts/adjusted-angle.jpeg";
import Mouldedsheet from "../../assets/mmsproducts/moduled-walkwayNew.jpg";
import FRPFencing from "../../assets/products/frpFencing.jpg";
import ladder2 from "../../assets/products/ladder2.jpeg";

import Typography from "@mui/material/Typography";

import { Box, List, ListItem, ListItemIcon } from "@mui/material";
import { Circle, DoneOutline, LineWeight } from "@mui/icons-material";

export const productList = [
  {
    ID: 0,
    name: "FRP PULTRUDED GRATING WALKWAY",
    img: walkway,
    Descripe:
      "A State-Of-The-Art Manufacturing Facility Produces Pultruded Profiles (Pultrusion Profiles- ‘I’ Section For Producing Horizontal And Vertical Walkway For Solar Rooftops). We Have All The Necessary Testing Certificates To Prove That Our Walkway Supplied Does Have A Longer Life Than The Other Moulded Grating Walkways.",
    Features: [
      "Light weight – Very Strong",
      "Easy to transport",
      "Anti-Skid Design",
      "UV Resistant",
      "Long life",
      "Corrosion Resistant",
      "Our Walkways have less than one third the weight of steel",
      "Easy to Install at site",
    ],
    Size: [
      "250mm X 20mm X 2000mm",
      "270mm X 20mm X 2000mm",
      "310mm X 20mm X 2000mm",
      "405mm X 20mm X 2000mm",
    ],
  },
  {
    ID: 1,
    name: "FRP HANDRAILS(Barricades/ Guard Rails)",
    img: Handrail,
    Descripe:
      "They Are Installed At Site As An Added Measure To Prevent From Falling Through The Roof Space.   They Also Help In Increasing The Safety And Accessibility To Many Rooftops. It Prevents Many Dangerous And Injurious Falls From The Rooftops.",
    Features: [
      "Light weight and easy to install",
      "Anti-Corrosive",
      "Cost effective",
      "Durable that steel handrails",
      "Strong enough to take any kind of load",
      "UV Resistant",
      "Comparatively much sturdier",
      "Power to withstand weather conditions",
    ],
    Size: ["900mm X 50mm X 50mm", "750mm X 50mm X 50mm"],
  },
  {
    ID: 2,
    name: "Angle Adjusted | Inclination Walkway",
    img: AAIW,
    Descripe: "",
    Features: [
      "Can be adjust to maintain angle flat walkway",
      "Light Weight",
      "Easy to Transport & Install at site",
      "No Corrosion",
      "Anti-Skid Deign",
      "UV Resistance",
    ],
    Size: [
      "250mm X 20mm X 2000mm",
      "270mm X 20mm X 2000mm",
      "310mm X 20mm X 2000mm",
      "405mm X 20mm X 2000mm",
    ],
  },
  {
    ID: 3,
    name: "Moulded Walkway",
    img: Mouldedsheet,
    Descripe: "",
    Features: [
      "Box Type and Sheet Type",
      "Can be adjust to maintain angle flat walkway",
      "Light Weight",
      "Easy to Transport & Install at site",
      "No Corrosion",
      "Anti-Skid Deign",
      "UV Resistance",
    ],
    Size: [],
  },
  {
    ID: 4,
    name: "FRP Fencing",
    img: FRPFencing,
    Descripe: "",
    Features: [],
    Size: [],
  },
  {
    ID: 5,
    name: "FRP LADDER AND STAIRCASE",
    img: ladder2,
    Descripe: "",
    Features: [],
    Size: [],
  },
];

export const FRP_PULTRUDED_GRATING_WALKWAY = () => {
  return (
    <Box
      sx={{
        width: "90%",
        height: "auto",
        display: "block",
        overFlow: "hidden",
      }}
    >
      <Box
        sx={{
          maxHeight: { lg: "600px", xs: "300px" },
          width: "100%",
          height: "100%",
          overflow: "hidden",
          borderRadius: "10px",
          justifySelf: "center",
        }}
      >
        <img width={"100%"} src={walkway} alt="" />
      </Box>
      <Box sx={{ paddingTop: "1%" }} mt={2}>
        <Typography
          variant="h4"
          fontWeight={"bold"}
          paddingBottom={2}
          align="left"
          fontSize={{ xs: "20px", md: "24px" }}
          data-aos="fade"
        >
          FRP PULTRUDED GRATING WALKWAY
        </Typography>
        <Typography
          variant="h6"
          letterSpacing={2}
          align="left"
          fontSize={{ xs: "12px", md: "18px" }}
          data-aos="fade"
        >
          A State-Of-The-Art Manufacturing Facility Produces Pultruded Profiles
          (Pultrusion Profiles- ‘I’ Section For Producing Horizontal And
          Vertical Walkway For Solar Rooftops). We Have All The Necessary
          Testing Certificates To Prove That Our Walkway Supplied Does Have A
          Longer Life Than The Other Moulded Grating Walkways.
        </Typography>
        <Box sx={{ paddingTop: "3%" }}>
          <Typography
            variant="h5"
            fontWeight={"bold"}
            align="left"
            fontSize={{ xs: "20px", md: "24px" }}
            data-aos="fade"
          >
            {" "}
            FEATURES AND ADVANTAGES
          </Typography>
          <List>
            {[
              "Light weight – Very Strong",
              "Easy to transport",
              "Anti-Skid Design",
              "UV Resistant",
              "Long life",
              "Corrosion Resistant",
              "Our Walkways have less than one third the weight of steel",
              "Easy to Install at site",
            ].map((ListValue, index) => (
              <ListItem key={index} data-aos="fade">
                <ListItemIcon>
                  <DoneOutline />
                </ListItemIcon>
                <Typography
                  variant="p"
                  letterSpacing={2}
                  fontWeight={"medium"}
                  align="left"
                  fontSize={{ xs: "12px", md: "18px" }}
                >
                  {ListValue}
                </Typography>
              </ListItem>
            ))}
          </List>

          <Typography
            variant="h5"
            fontWeight={"bold"}
            align="left"
            paddingTop={"2%"}
            fontSize={{ xs: "20px", md: "24px" }}
            data-aos="fade"
          >
            {" "}
            SIZES
          </Typography>
          <List>
            {[
              "250mm X 20mm X 2000mm",
              "270mm X 20mm X 2000mm",
              "310mm X 20mm X 2000mm",
              "405mm X 20mm X 2000mm",
            ].map((ListValue, index) => (
              <ListItem key={index} data-aos="fade">
                <ListItemIcon>
                  <DoneOutline />
                </ListItemIcon>
                <Typography
                  variant="p"
                  letterSpacing={2}
                  fontWeight={"medium"}
                  align="left"
                  fontSize={{ xs: "12px", md: "18px" }}
                >
                  {ListValue}
                </Typography>
              </ListItem>
            ))}
          </List>
        </Box>
      </Box>
    </Box>
  );
};

export const FRP_HANDRAILS = () => {
  return (
    <Box sx={{ width: "90%", height: "auto", display: "block" }}>
      <Box
        sx={{
          maxHeight: { lg: "600px", xs: "300px" },
          width: "100%",
          height: "100%",
          overflow: "hidden",
          borderRadius: "10px",
          justifySelf: "center",
        }}
        data-aos="fade"
      >
        <img width={"100%"} src={Handrail} alt="" />
      </Box>
      <Box sx={{ paddingTop: "1%" }} mt={2}>
        <Typography
          variant="h4"
          fontWeight={"bold"}
          paddingBottom={2}
          align="left"
          fontSize={{ xs: "20px", md: "24px" }}
          data-aos="fade"
        >
          FRP HANDRAILS(Barricades/ Guard Rails)
        </Typography>
        <Typography
          variant="h6"
          letterSpacing={2}
          align="left"
          fontSize={{ xs: "12px", md: "18px" }}
          data-aos="fade"
        >
          They Are Installed At Site As An Added Measure To Prevent From Falling
          Through The Roof Space. They Also Help In Increasing The Safety And
          Accessibility To Many Rooftops. It Prevents Many Dangerous And
          Injurious Falls From The Rooftops.
        </Typography>
        <Box sx={{ paddingTop: "3%" }}>
          <Typography
            variant="h5"
            fontWeight={"bold"}
            align="left"
            fontSize={{ xs: "20px", md: "24px" }}
            data-aos="fade"
          >
            {" "}
            FEATURES AND ADVANTAGES
          </Typography>
          <List>
            {[
              "Light weight and easy to install",
              "Anti-Corrosive",
              "Cost effective",
              "Durable that steel handrails",
              "Strong enough to take any kind of load",
              "UV Resistant",
              "Comparatively much sturdier",
              "Power to withstand weather conditions",
            ].map((ListValue, index) => (
              <ListItem key={index} data-aos="fade">
                <ListItemIcon>
                  <DoneOutline />
                </ListItemIcon>
                <Typography
                  variant="p"
                  letterSpacing={2}
                  fontWeight={"medium"}
                  align="left"
                  fontSize={{ xs: "12px", md: "18px" }}
                >
                  {ListValue}
                </Typography>
              </ListItem>
            ))}
          </List>

          <Typography
            variant="h5"
            fontWeight={"bold"}
            align="left"
            paddingTop={"2%"}
            data-aos="fade"
            fontSize={{ xs: "20px", md: "24px" }}
          >
            {" "}
            SIZES
          </Typography>
          <List>
            {["900mm X 50mm X 50mm", "750mm X 50mm X 50mm"].map(
              (ListValue, index) => (
                <ListItem key={index} data-aos="fade">
                  <ListItemIcon>
                    <DoneOutline />
                  </ListItemIcon>
                  <Typography
                    variant="p"
                    letterSpacing={2}
                    fontWeight={"medium"}
                    align="left"
                    fontSize={{ xs: "12px", md: "18px" }}
                  >
                    {ListValue}
                  </Typography>
                </ListItem>
              )
            )}
          </List>
          <Typography
            variant="h6"
            fontWeight={"bold"}
            align="left"
            paddingTop={"2%"}
            data-aos="fade"
            fontSize={{ xs: "20px", md: "24px" }}
          >
            {" "}
            Note:
          </Typography>
          <Typography
            variant="h6"
            align="left"
            data-aos="fade"
            fontSize={{ xs: "18px", md: "22px" }}
          >
            {" "}
            Our Complete Package Of Handrails Include:
          </Typography>
          <List>
            {[
              "50X50X5mm Square pillar",
              "3 Mtr Round Pipe",
              "Base Shoe",
              "Joining clamps",
              "Base plate (Given at extra cost for installation)",
            ].map((ListValue, index) => (
              <ListItem key={index} data-aos="fade">
                <ListItemIcon>
                  <Circle />
                </ListItemIcon>
                <Typography
                  variant="p"
                  letterSpacing={2}
                  fontWeight={"medium"}
                  align="left"
                  fontSize={{ xs: "12px", md: "18px" }}
                >
                  {ListValue}
                </Typography>
              </ListItem>
            ))}
          </List>
          <Typography
            variant="h6"
            fontWeight={"bold"}
            align="left"
            paddingTop={"2%"}
            fontSize={{ xs: "18px", md: "22px" }}
            data-aos="fade"
          >
            Base plate:
          </Typography>

          <List>
            {[
              "For easier installation of frp handrails we have introduced a sheet called base plate which can used instead of a GI strip",
              "It can be installed in horizontal and vertical ways",
              "This is given at extra cost and is at customers wish",
              "Base Plate – 290mmX150mmX5mm",
            ].map((ListValue, index) => (
              <ListItem key={index} data-aos="fade">
                <ListItemIcon>
                  <Circle />
                </ListItemIcon>
                <Typography
                  variant="p"
                  letterSpacing={2}
                  fontWeight={"medium"}
                  align="left"
                  fontSize={{ xs: "12px", md: "18px" }}
                >
                  {ListValue}
                </Typography>
              </ListItem>
            ))}
          </List>
        </Box>
      </Box>
    </Box>
  );
};

export const Angle_Adjusted = () => {
  return (
    <Box sx={{ width: "90%", height: "auto", display: "block" }}>
      <Box
        sx={{
          maxHeight: { lg: "600px", xs: "300px" },
          width: "100%",
          height: "100%",
          overflow: "hidden",
          borderRadius: "10px",
          justifySelf: "center",
        }}
        data-aos="fade"
      >
        <img width={"100%"} src={AAIW} alt="" />
      </Box>
      <Box sx={{ paddingTop: "1%" }}>
        <Typography
          variant="h4"
          fontWeight={"bold"}
          paddingBottom={2}
          align="left"
          fontSize={{ xs: "20px", md: "24px" }}
          data-aos="fade"
        >
          Angle Adjusted | Inclination Walkway
        </Typography>
        <Box sx={{ paddingTop: "3%" }}>
          <Typography
            variant="h5"
            fontWeight={"bold"}
            align="left"
            fontSize={{ xs: "20px", md: "24px" }}
            data-aos="fade"
          >
            {" "}
            FEATURES
          </Typography>
          <List>
            {[
              "Can be adjust to maintain angle flat walkway",
              "Light Weight",
              "Easy to Transport & Install at site",
              "No Corrosion",
              "Anti-Skid Deign",
              "UV Resistance",
            ].map((ListValue, index) => (
              <ListItem key={index} data-aos="fade">
                <ListItemIcon>
                  <DoneOutline />
                </ListItemIcon>
                <Typography
                  variant="p"
                  letterSpacing={2}
                  fontWeight={"medium"}
                  align="left"
                  fontSize={{ xs: "12px", md: "18px" }}
                >
                  {ListValue}
                </Typography>
              </ListItem>
            ))}
          </List>
        </Box>
      </Box>
    </Box>
  );
};

export const Moulded_Walkway = () => {
  return (
    <Box sx={{ width: "90%", height: "auto", display: "block" }}>
      <Box
        sx={{
          maxHeight: { lg: "600px", xs: "300px" },
          width: "100%",
          height: "100%",
          overflow: "hidden",
          borderRadius: "10px",
          justifySelf: "center",
        }}
        data-aos="fade"
      >
        <img width={"100%"} src={Mouldedsheet} alt="" />
      </Box>
      <Box sx={{ paddingTop: "1%" }}>
        <Typography
          variant="h4"
          fontWeight={"bold"}
          paddingBottom={2}
          align="left"
          fontSize={{ xs: "20px", md: "24px" }}
          data-aos="fade"
        >
          Moulded Walkway
        </Typography>
        <Box sx={{ paddingTop: "3%" }}>
          <Typography
            variant="h5"
            fontWeight={"bold"}
            align="left"
            fontSize={{ xs: "20px", md: "24px" }}
            data-aos="fade"
          >
            {" "}
            FEATURES
          </Typography>
          <List>
            {[
              "Box Type and Sheet Type",
              "Can be adjust to maintain angle flat walkway",
              "Light Weight",
              "Easy to Transport & Install at site",
              "No Corrosion",
              "Anti-Skid Deign",
              "UV Resistance",
            ].map((ListValue, index) => (
              <ListItem key={index} data-aos="fade">
                <ListItemIcon>
                  <DoneOutline />
                </ListItemIcon>
                <Typography
                  variant="p"
                  letterSpacing={2}
                  fontWeight={"medium"}
                  align="left"
                  fontSize={{ xs: "12px", md: "18px" }}
                >
                  {ListValue}
                </Typography>
              </ListItem>
            ))}
          </List>
        </Box>
      </Box>
    </Box>
  );
};

export const FRP_Fencing = () => {
  return (
    <Box sx={{ width: "90%", height: "auto", display: "block" }}>
      <Box
        sx={{
          maxHeight: { lg: "600px", xs: "300px" },
          width: "100%",
          height: "100%",
          overflow: "hidden",
          borderRadius: "10px",
          justifySelf: "center",
        }}
        data-aos="fade"
      >
        <img width={"100%"} src={FRPFencing} alt="" />
      </Box>
      <Box sx={{ paddingTop: "1%" }} mt={2}>
        <Typography
          variant="h4"
          fontWeight={"bold"}
          paddingBottom={2}
          align="left"
          fontSize={{ xs: "20px", md: "24px" }}
          data-aos="fade"
        >
          FRP Fencing
        </Typography>
        <Typography
          variant="h6"
          letterSpacing={2}
          align="left"
          fontSize={{ xs: "12px", md: "18px" }}
          data-aos="fade"
        >
          Available in Customisable sizes
        </Typography>
        <Box sx={{ paddingTop: "3%" }}>
          <Typography
            variant="h5"
            fontWeight={"bold"}
            align="left"
            fontSize={{ xs: "20px", md: "24px" }}
            data-aos="fade"
          >
            {" "}
            FEATURES
          </Typography>
          <List>
            {[
              "Non conductive solution",
              "High strength",
              "Weather proof/ Non corrosive",
              "Fire Proof",
              "Maintenance free",
              "Can be used for multiple applications ",
            ].map((ListValue, index) => (
              <ListItem key={index} data-aos="fade">
                <ListItemIcon>
                  <DoneOutline />
                </ListItemIcon>
                <Typography
                  variant="p"
                  letterSpacing={2}
                  fontWeight={"medium"}
                  align="left"
                  fontSize={{ xs: "12px", md: "18px" }}
                >
                  {ListValue}
                </Typography>
              </ListItem>
            ))}
          </List>
        </Box>
      </Box>
    </Box>
  );
};

export const FRP_LADDER_AND_STAIRCASE = () => {
  return (
    <Box sx={{ width: "90%", height: "auto", display: "block" }}>
      <Box
        sx={{
          maxHeight: { lg: "600px", xs: "300px" },
          width: "100%",
          height: "100%",
          overflow: "hidden",
          borderRadius: "10px",
          justifySelf: "center",
        }}
        data-aos="fade"
      >
        <img width={"100%"} src={ladder2} alt="" />
      </Box>
      <Box sx={{ paddingTop: "1%" }} mt={2}>
        <Typography
          variant="h4"
          fontWeight={"bold"}
          paddingBottom={2}
          align="left"
          fontSize={{ xs: "20px", md: "24px" }}
          data-aos="fade"
        >
          FRP LADDER AND STAIRCASE
        </Typography>
        <Typography
          variant="h6"
          letterSpacing={2}
          align="left"
          fontSize={{ xs: "12px", md: "18px" }}
          data-aos="fade"
        >
          Available in Customisable sizes
        </Typography>
        <Box sx={{ paddingTop: "3%" }}>
          <Typography
            variant="h5"
            fontWeight={"bold"}
            align="left"
            fontSize={{ xs: "20px", md: "24px" }}
            data-aos="fade"
          >
            {" "}
            FEATURES
          </Typography>
          <List>
            {[
              "Non conductive solution",
              "High strength",
              "Slip resistant treads",
              "Heat resistant",
              "100% rust & weather proof",
              " Safe and Durable",
              "Shock Proof",
            ].map((ListValue, index) => (
              <ListItem key={index} data-aos="fade">
                <ListItemIcon>
                  <DoneOutline />
                </ListItemIcon>
                <Typography
                  variant="p"
                  letterSpacing={2}
                  fontWeight={"medium"}
                  align="left"
                  fontSize={{ xs: "12px", md: "18px" }}
                >
                  {ListValue}
                </Typography>
              </ListItem>
            ))}
          </List>
        </Box>
      </Box>
    </Box>
  );
};
